import {
  TracerConfig,
  WebTracerProvider,
  BatchSpanProcessor,
} from '@opentelemetry/sdk-trace-web';
import { trace } from '@opentelemetry/api';
import { Resource } from '@opentelemetry/resources';
import type { ResourceAttributes } from '@opentelemetry/resources';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import * as logsAPI from '@opentelemetry/api-logs';
import {
  LoggerProvider,
  BatchLogRecordProcessor,
  SimpleLogRecordProcessor,
} from '@opentelemetry/sdk-logs';
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { ConsoleLogExporter } from './exporters/console-exporter';

interface Config {
  env: 'production' | 'staging' | 'dev';
  outputToConsole?: boolean;
  enableAutoInstrumentation?: boolean;
  context?: ResourceAttributes;
}

export function init({
  env,
  outputToConsole,
  enableAutoInstrumentation = true,
  context = {},
}: Config): void {
  const providerConfig: TracerConfig = {
    resource: new Resource(context),
  };
  const globalProvider = new WebTracerProvider(providerConfig);
  const loggerProvider = new LoggerProvider(providerConfig);
  if (['production', 'staging'].includes(env)) {
    const baseUrl =
      env === 'production'
        ? 'https://otel-collector.moonpaycloud.com'
        : 'https://otel-collector.moonpay-staging.com';
    const collectorSharedConfig = {
      headers: {
        Authorization: 'Bearer vsT5uZSiX3dWe5s5bw6uEiAnC1ihqK2J',
      },
    };

    globalProvider.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          ...collectorSharedConfig,
          url: `${baseUrl}/v1/traces`,
        }),
      ),
    );
    loggerProvider.addLogRecordProcessor(
      new BatchLogRecordProcessor(
        new OTLPLogExporter({
          ...collectorSharedConfig,
          url: `${baseUrl}/v1/logs`,
        }),
      ),
    );
  }
  if (env === 'dev' || outputToConsole) {
    loggerProvider.addLogRecordProcessor(
      new SimpleLogRecordProcessor(new ConsoleLogExporter()),
    );
  }

  globalProvider.register({
    contextManager: new ZoneContextManager(),
  });

  if (enableAutoInstrumentation) {
    registerInstrumentations({
      instrumentations: [
        new FetchInstrumentation({
          ignoreUrls: [/launchdarkly\.com/],
          propagateTraceHeaderCorsUrls: [
            /.*\.moonpay\.com/,
            /.*\.moonpaycloud\.com/,
            /localhost/,
          ],
        }),
      ],
    });
  }
  globalProvider.register();
  logsAPI.logs.setGlobalLoggerProvider(loggerProvider);
  trace.setGlobalTracerProvider(globalProvider);
}
